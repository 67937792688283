import {
  ArrowBack,
  Logout,
  MapOutlined,
  Menu as MenuIcon,
  Person,
  Search,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { applyTo, filter, head, identity, pipe, split } from "ramda";
import { memo, useCallback, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { displayName } from "lib/react";

import { useAppContext } from "components/AppContext";
import { useAuth } from "components/AuthContext";
import Drawer from "components/Layout/components/Drawer";
import logo from "./logo.svg";

const EVEN_MOBILE_HEADER_WIDTH = 75;

const WIDE_HEADER_BASE_NAMES = ["explore"];

const getBaseName = pipe(split("/"), filter(identity), head);

export default applyTo(() => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const { title, headerContext, backPath } = useAppContext();

  const { isAuthenticated } = useAuth();

  const handleToggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen, setMenuOpen]);

  const isContainerized = useMemo(() => {
    const baseName = getBaseName(pathname);
    if (pathname === "/") return true;
    return !WIDE_HEADER_BASE_NAMES.includes(baseName);
  }, [pathname]);

  const desktop = useMemo(
    () => (
      <Toolbar as="nav" component="nav" disableGutters={isContainerized}>
        <Box
          as={Link}
          href="/"
          sx={{ width: 100 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box component="img" src={logo} data-cy="desktop-logo" width="100%" />
        </Box>
        <Stack
          direction="row"
          gap={5}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {!isAuthenticated && (
            <>
              <Typography
                component={Link}
                to="/about"
                variant="body1"
                sx={{ fontWeight: 100 }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                About
              </Typography>
              <Typography
                component={Link}
                to="/contact"
                variant="body1"
                sx={{ fontWeight: 100 }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Contact
              </Typography>
              <Typography
                component={Link}
                to="/login"
                variant="body1"
                sx={{ fontWeight: 100 }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Log in
              </Typography>
              <Typography
                component={Link}
                to="/signup"
                variant="body1"
                sx={{ fontWeight: 100 }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Sign up
              </Typography>
            </>
          )}

          <IconButton component={Link} to="/search" title="search">
            <Search />
          </IconButton>
          {isAuthenticated && (
            <IconButton component={Link} to="/profile" title="profile">
              <Person />
            </IconButton>
          )}
          {isAuthenticated && (
            <IconButton component={Link} to="/logout" title="logout">
              <Logout />
            </IconButton>
          )}
          <Button component={Link} to="/explore" startIcon={<MapOutlined />}>
            Explore
          </Button>
        </Stack>
      </Toolbar>
    ),
    [isAuthenticated, isContainerized]
  );

  const mobile = useMemo(() => {
    if (title !== null) {
      return (
        <Toolbar as="nav" component="nav" style={{ height: 100, width: '100vw', boxShadow: '0px 1px 3px #666666' }}>
          <Stack
            direction="row"
            gap={1}
            display="grid"
            gridTemplateColumns="minmax(0,1fr) auto"
            sx={{ width: "100%", overflow: 'hidden' }}
          >
            <Stack direction="row" alignItems='center' gap={2}>
              {backPath !== null && (
                <IconButton
                  onClick={() => navigate(backPath)}
                  color="inherit"
                  edge="end"
                  sx={{ marginLeft: -1 }}>
                  <ArrowBack style={{ color: "black" }} data-cy="back" />
                </IconButton>
              )}
              <Typography variant="h5" noWrap flex={1}>{title}</Typography>
            </Stack>
            <Stack direction="row" alignItems='center' gap={1}>
              {headerContext}
            </Stack>
          </Stack>
        </Toolbar>
      );
    }
    return (
      <Toolbar as="nav" component="nav" style={{ height: 100, boxShadow: '0px 0px 1px #666666' }}>
        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Box sx={{ width: EVEN_MOBILE_HEADER_WIDTH }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleToggleMenu}
              sx={{ marginLeft: -1 }}
            >
              <MenuIcon style={{ color: "black" }} data-cy="burger" />
            </IconButton>
          </Box>
          <Box
            component={Link}
            href="/"
            sx={{ width: 100 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box component="img" src={logo} data-cy="mobile-logo" maxHeight={40} />
          </Box>
          <Box sx={{ width: EVEN_MOBILE_HEADER_WIDTH }} />
        </Stack>
      </Toolbar>
    )
  }, [handleToggleMenu, navigate, headerContext, title, backPath]);

  const content = useMemo(() => {
    if (!isMobile) {
      return isContainerized ? <Container sx={{ paddingTop: 0, paddingBottom: 0 }}>{desktop}</Container> : desktop;
    }
    return mobile;
  }, [isMobile, desktop, mobile, isContainerized]);

  const appBarHeight = useMemo(() => {
    // desktop
    if (!isMobile) {
      return pathname === "/explore" ? 100 : 150;
    }
    // mobile
    return 70;
  }, [isMobile, pathname]);

  const appBarMarginBottom = useMemo(() => {
    if (isMobile && pathname === "/") {
      return 2;
    }
    return 0;
  }, [isMobile, pathname]);

  return (
    <AppBar
      position="static"
      variant="header"
      sx={{
        height: appBarHeight,
        marginBottom: appBarMarginBottom,
        justifyContent: "center"
      }}
    >
      {content}
      <SwipeableDrawer
        anchor="left"
        open={menuOpen}
        onClose={handleToggleMenu}
        onOpen={handleToggleMenu}
        PaperProps={{ sx: { width: 300, background: "#FDF9ED" } }}
      >
        <Drawer close={handleToggleMenu} />
      </SwipeableDrawer>
    </AppBar>
  );
}, pipe(displayName("Header"), memo));
